// @file Store for user experiments
import { defineStore } from 'pinia'
import { ref } from 'vue'

export enum UpgradeModalCopyAExperimentVariant {
  Control = 'control',
  Variation = 'variation',
}

export enum UpgradeModalSingleStepExperimentVariant {
  Control = 'control',
  Variation = 'variation',
}

export const useExperimentsStore = defineStore('experiments', () => {
  // State
  /**
   * Experiment: `upgrade_modal_copy_a`
   */
  const upgradeModalCopyAExperimentVariant = ref<UpgradeModalCopyAExperimentVariant>(
    UpgradeModalCopyAExperimentVariant.Control,
  )

  const upgradeModalSingleStepExperimentVariant = ref<UpgradeModalSingleStepExperimentVariant>(
    UpgradeModalSingleStepExperimentVariant.Control,
  )

  const setUpgradeModalCopyAExperimentVariant = (
    upgradeModalCopyAExperimentVariantValue: UpgradeModalCopyAExperimentVariant | undefined,
  ): void => {
    upgradeModalCopyAExperimentVariant.value =
      upgradeModalCopyAExperimentVariantValue ?? UpgradeModalCopyAExperimentVariant.Control
  }

  const setUpgradeModalSingleStepExperimentVariant = (
    upgradeModalSingleStepExperimentVariantValue: UpgradeModalSingleStepExperimentVariant | undefined,
  ): void => {
    upgradeModalSingleStepExperimentVariant.value =
      upgradeModalSingleStepExperimentVariantValue ?? UpgradeModalSingleStepExperimentVariant.Control
  }

  return {
    // State
    upgradeModalCopyAExperimentVariant,
    upgradeModalSingleStepExperimentVariant,

    // Action
    setUpgradeModalCopyAExperimentVariant,
    setUpgradeModalSingleStepExperimentVariant,
  }
})
